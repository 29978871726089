import React from 'react'
import InscriptionForm from '../InscriptionForm/InscriptionForm'

const AdditionalServicePSP: React.FC = () => {
  return (
    <section className="py-layout-5 bg-light-gray-1-5">
      <div className="container">
        <InscriptionForm formURL="https://form.jotform.com/221174481731048"/>
      </div>
    </section>
  )
}

export default AdditionalServicePSP