import React from 'react'
import SEO from '../../components/SEO/SEO';
import Footer from '../../components/Footer/Footer';

import InscriptionHero from '../../components/Inscription/InscriptionHero/InscriptionHero';
import AdditionalServicePSP from '../../components/Inscription/AdditionalServicePSP/AdditionalServicePSP';
import { LocaleTypes } from '../../data/locales';
import IndexLayout from '../../layouts';
import { FormattedMessage } from 'react-intl';

interface additionalServicePSPProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const additionalServicePSP: React.FC<additionalServicePSPProps> = ({ pathContext, location }) => {

  const content = () => (
    <>
      <SEO titleId="inscription.additional_psp.title" descriptionId="inscription.additional_psp.description" />

      <InscriptionHero>
        <h1>
          <FormattedMessage id="inscription.additional_psp.title" />
        </h1>
        <p>
          <FormattedMessage id="inscription.additional_psp.description" />
        </p>
      </InscriptionHero>
      
      <AdditionalServicePSP />

      <Footer theme="primary" />

    </>
  )
  return (
    <IndexLayout
      render={ content }
      locale={pathContext.localeCode}
      navbarTheme="light"
      location={location}
    />
  )
}

export default additionalServicePSP 